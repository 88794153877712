






































































































































































































































































































.templateList {
    .el-form-item-val {
        width: 50%;
        max-height: 10rem;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        padding-left: 20px;
        overflow-y: auto;

        .el-checkbox-group {
            display: flex;
            flex-direction: column;
        }
    }

    .customBtn:nth-child(2) {
        background-color: #5C6BE8;
        border: none;

        &:hover {
            background-color: #6875ee;
        }
    }
}
